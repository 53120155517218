import { showFullpageLoader } from 'app-basic/lib/ab-tests/ab-tests-slice';
import { getLoaderVisible } from 'app-basic/lib/ab-tests/selectors';
import { useABTest_Fullstory_LoggedOut } from 'app-basic/lib/ab-tests/tests/LIVE-20738_FullstoryForLoggedOut';
import { basicWrapper } from 'app-basic/lib/basic-store';
import { addI18nHeaderToFetch } from 'app-basic/lib/i18n/utils/add-i18n-header-to-fetch';
import { mergeI18nTranslations } from 'app-basic/lib/i18n/utils/merge-i18n-translations';
import { LayoutBasic } from 'app-basic/lib/layout-basic';
import { useSelector } from 'app-basic/lib/utils';
import { appWithTranslation } from 'next-i18next';
import React, { ComponentType, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { init as initFullStory } from '@fullstory/browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FullpageLoader from 'app-shared/components/fullpage-loader/fullpage-loader';
import { FULLSTORY_ORG_ID as orgId } from 'app-shared/lib/utils/constants';
import { IAppProps } from 'pages/_app';
import { useSetMarketCookie } from 'lib/auth/hooks/use-set-market-cookie-listener';
import nextI18NextConfig from '../next-i18next.app-basic.config';

addI18nHeaderToFetch();

const queryClient = new QueryClient();

/**
 * Full page loader covers the page until AB tests are loaded
 */
const LoaderOverlay = () => {
  const loaderVisible = useSelector(getLoaderVisible);
  const dispatch = useDispatch();

  // Loader only covers the server-side rendered page and hides when client is initialized.
  // This is in case a client displays a different variant of the page.
  useEffect(() => {
    dispatch(showFullpageLoader(false));
  }, [dispatch]);

  return loaderVisible ? <FullpageLoader /> : null;
};

const AppBasic = (props: IAppProps) => {
  const { Component, renderer, pageProps, router, ...rest } = props;
  const { variant: fullStoryLoggedOutVariant } = useABTest_Fullstory_LoggedOut();

  useEffect(() => {
    if (fullStoryLoggedOutVariant === '1') {
      initFullStory({ orgId });
    }
  }, [fullStoryLoggedOutVariant]);

  useSetMarketCookie();

  return (
    <QueryClientProvider client={queryClient}>
      <LayoutBasic renderer={renderer} router={router}>
        <LoaderOverlay />
        <Component {...pageProps} {...rest} />
      </LayoutBasic>
    </QueryClientProvider>
  );
};

export default compose<ComponentType<IAppProps>>(
  mergeI18nTranslations,
  basicWrapper.withRedux,
  (Comp: IAppProps['Component']) => appWithTranslation(Comp, nextI18NextConfig),
)(AppBasic);
