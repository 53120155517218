import { isEmpty } from 'ramda';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialIsMainLiveCDN } from 'lib/app/constants';
import { AlternateLink } from 'lib/types';

const initialState: BasicAppState = {
  canonicalLink: '',
  alternateLinks: [],
  pathname: '',
  locale: '',
  previousPath: '',
  device: {
    host: 'bridebook.com',
    width: 0,
    height: 0,
    isMobileUA: false,
    isTabletUA: false,
    isCordova: false,
    isMainLiveCDN: initialIsMainLiveCDN,
  },
};

export interface BasicDeviceState {
  host: string;
  width: number;
  height: number;
  isMobileUA: boolean;
  isTabletUA: boolean;
  isCordova: boolean;
  serverCountryCode?: CountryCodes | null;
  isMainLiveCDN: boolean;
}

export interface BasicAppState {
  canonicalLink: string;
  alternateLinks: AlternateLink[];
  pathname: string;
  locale: string;
  previousPath: string;
  device: BasicDeviceState;
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDevice(state, { payload }: PayloadAction<BasicAppState['device']>) {
      state.device.isMainLiveCDN = payload.isMainLiveCDN;
      state.device.isCordova = payload.isCordova;
      state.device.serverCountryCode = payload.serverCountryCode;
      state.device.width = payload.width;
      state.device.height = payload.height;
      state.device.isMobileUA = payload.isMobileUA;
      state.device.isTabletUA = payload.isTabletUA;
    },
    setCanonicalLink(state, { payload }: PayloadAction<string>) {
      state.canonicalLink = payload;
    },

    setAlternateLinks(state, { payload }: PayloadAction<AlternateLink[]>) {
      state.alternateLinks = payload;
    },
  },
});

export const isInitialDeviceState = (value: any): value is BasicDeviceState => !isEmpty(value);
export const { setDevice, setCanonicalLink, setAlternateLinks } = appSlice.actions;
export default appSlice.reducer;
