import { mapToPackagesSections } from '@bridebook/toolbox';
import {
  type ISupplierPackage,
  sortSupplierPackages,
} from '@bridebook/toolbox/src/map-packages-to-sections';
import { FetchSupplierDataResponse } from '@bridebook/toolbox/src/supplier/fetch-supplier-data';
import type { IFeedbackResponse } from '@bridebook/toolbox/src/supplier/get-feedback';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Reviews from 'app-shared/lib/supplier/reviews';
import { IFair, InstagramMedia, Reviews as ReviewsType } from 'app-shared/lib/supplier/types';

export type BasicSupplierDetails = FetchSupplierDataResponse & {
  accordions: string[];
  fairs?: IFair[];
  errorCode?: number;
  reviews: ReviewsType;
  instagramMedia?: InstagramMedia[] | null;
  supplierPackages?: ISupplierPackage[];
};

const initialState: Omit<
  BasicSupplierDetails,
  'appsConnected' | 'deleted' | 'premium' | 'seoIndex'
> & {
  premium: BasicSupplierDetails['premium'] | null;
} = {
  accordions: [
    'pricing-accordion',
    'features-accordion',
    'description-accordion',
    'testimonials-accordion',
    'location-accordion',
    'awards-accordion',
    'faq-accordion',
    'preferred-suppliers-accordion',
  ],
  premium: null,
  photos: [],
  offers: [],
  badges: [],
  packages: {},
  supplierPackages: [],
  videos: [],
  brochures: [],
  achievements: [],
  recommendations: [],
  questions: [],
  fairs: [],
  relations: [],
  reviews: { ...Reviews },
  seoScore: 0,
};

const supplierDetailsSlice = createSlice({
  name: 'supplierDetails',
  initialState,
  reducers: {
    saveSupplierDetails: (state, { payload }: PayloadAction<FetchSupplierDataResponse>) => {
      Object.assign(state, {
        ...payload,
        packages: mapToPackagesSections(payload.packages),
        supplierPackages: sortSupplierPackages(payload.packages),
      });
    },
    saveFeedbackDetails: (state, { payload }: PayloadAction<IFeedbackResponse>) => {
      state.reviews = {
        ...state.reviews,
        ...payload,
        // @ts-expect-error Expected error due to FS serialized
        feedback: payload.feedback,
        loading: false,
      };
    },
    fetchSupplierError: (state, { payload }: PayloadAction<number>) => {
      state.errorCode = payload;
    },
    toggleSupplierAccordion: (state, { payload }: PayloadAction<string>) => {
      const index = state.accordions.indexOf(payload);
      if (index === -1) {
        state.accordions.push(payload);
      } else {
        state.accordions.splice(index, 1);
      }
    },
    saveInstagramMedia(state, { payload }: PayloadAction<InstagramMedia[] | null>) {
      state.instagramMedia = payload;
    },
  },
});

export const {
  saveSupplierDetails,
  saveFeedbackDetails,
  toggleSupplierAccordion,
  fetchSupplierError,
  saveInstagramMedia,
} = supplierDetailsSlice.actions;
export default supplierDetailsSlice.reducer;
