import { participatedInABTestAnalytics } from 'app-basic/lib/ab-tests/analytics';
import { getTest } from 'app-basic/lib/ab-tests/selectors';
import { useSelector } from 'app-basic/lib/utils';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAbTestVariant } from '@bridebook/toolbox/src/ab-testing';
import Experiments from '@bridebook/toolbox/src/ab-testing/ab-testing';
import { getIsTestActivated } from '@bridebook/toolbox/src/ab-testing/lib/selectors';
import { CohortNames } from '@bridebook/toolbox/src/ab-testing/lib/types';
import { basicUserIdCookie } from '@bridebook/toolbox/src/ab-testing/lib/utils/cookies';
import { getIsE2E } from '@bridebook/toolbox/src/e2e/get-is-e2e';

const SALT = '3b3652a7ca980aa1f7c9a31b44b230f7';
export const BASIC_USER_ID_COOKIE = 'basic_client_id';

/**
 * Activates an AB Test for app-basic
 *
 * HOW IT WORKS:
 *
 * --- Server-Side Rendering -----------------
 * During server-side rendering, a userId is empty therefore it returns `control`.
 *
 * --- First Client-Side Rendering -----------
 * During the first client-side rendering, the userId is still empty, and it returns `control` as well,
 * so that the versions returned by server and client are matching.
 * If we set a userId immediately, we would get server-client mismatch error.
 *
 * After this, a useEffect kicks in and sets a userId cookie, which returns a proper variant.
 * Between SSR and this moment, there should be a full-page loader covering any "flickering" of variants.
 *
 * --- Subsequent Client-Side Navigation -----
 * During further client-side navigation there is no SSR, but there is already a userId cookie set,
 * thanks to which we can return a proper variant immediately during the first render,
 * so that there is no need for a full-page loader.
 *
 * @param testId - id of the test from firestore
 * @param triggerCondition (optional) - test will run only if this is undefined or true
 */
export const useBasicABTest = (testId: string, triggerCondition: boolean = true) => {
  const [userId, setUserId] = useState(basicUserIdCookie.get(BASIC_USER_ID_COOKIE));

  // The first client-side render has to match the server, so we can set a user id
  // only in the second render using `useEffect`
  useEffect(() => {
    if (userId) return;
    setUserId(basicUserIdCookie.set(BASIC_USER_ID_COOKIE));
  }, [userId]);

  const abTest = useSelector((state) => getTest(state, testId));
  const isTestActivated = useSelector((state) => getIsTestActivated(state, testId));
  const dispatch = useDispatch();

  const shouldActivateTest = useMemo(
    () => Boolean(userId && abTest && !getIsE2E() && triggerCondition),
    [userId, abTest, triggerCondition],
  );

  const experiment = useMemo(() => {
    if (shouldActivateTest) {
      return new Experiments(
        {
          version: '1.0',
          experiments: [abTest],
          salt: SALT,
        },
        userId,
        { id: userId },
      );
    }
    return null;
  }, [shouldActivateTest, abTest, userId]);

  const variant = useMemo(
    () => (experiment?.getCohort(testId) || 'control') as CohortNames,
    [experiment, testId],
  );

  // Mark the test as activated and dispatch analytics
  useEffect(() => {
    if (abTest && !isTestActivated && shouldActivateTest) {
      dispatch(setAbTestVariant({ id: testId, variant }));
      dispatch(participatedInABTestAnalytics({ abTest, variant }));
    }
  }, [abTest, isTestActivated, shouldActivateTest, variant, dispatch, testId]);

  return { variant };
};
