import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ILatLongBounds } from 'app-shared/lib/search/types';

export type BasicSearchBarInfoState = {
  source: string | null;
  bounds: null | ILatLongBounds;
};

const initialState: BasicSearchBarInfoState = {
  source: null,
  bounds: null,
};

const searchBarInfo = createSlice({
  name: 'searchBarInfo',
  initialState,
  reducers: {
    saveSearchSource: (state, { payload }: PayloadAction<string>) => {
      state.source = payload;
    },
    saveCountryBounds: (state, { payload }: PayloadAction<ILatLongBounds>) => {
      state.bounds = payload;
    },
  },
});

export const { saveSearchSource, saveCountryBounds } = searchBarInfo.actions;

export default searchBarInfo.reducer;
