import { TrackingCategory } from 'app-basic/lib/analytics/tracking-category';
import { ThunkAction } from 'app-basic/lib/types';
import { CohortNames, Experiment } from '@bridebook/toolbox/src/ab-testing/lib/types';
import { getParticipatedInAbTestPayload } from '@bridebook/toolbox/src/ab-testing/lib/utils/utils';

// A/B testing main analytics event for logged-out
export const participatedInABTestAnalytics: (payload: {
  abTest: Experiment;
  variant: CohortNames;
}) => ThunkAction<void> =
  ({ abTest, variant }) =>
  (_, __, { analyticsService }) =>
    analyticsService
      .getMethods(TrackingCategory.LoggedOut)
      .track(getParticipatedInAbTestPayload({ abTest, variant }));
