import { PostAuthor } from '@bridebook/toolbox/src/schemas/article/types';
import { IArticle as IRawArticle } from '@bridebook/toolbox/src/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IArticle, IRelatedContentType } from 'app-shared/lib/articles/types';

export type BasicArticlesState = {
  articlesMarketSupport: boolean;
  article: IArticle | null;
  relatedArticles: IArticle[] | null;
  relatedContent: IRelatedContentType | null;
  list: IRawArticle[];
  listLoaded: boolean;
  searchString: string;
  recordsFound: number | null;
  showAllTags: boolean;
  currentAuthor: PostAuthor | null;
};

const initialState: BasicArticlesState = {
  article: null,
  relatedArticles: null,
  relatedContent: null,
  list: [],
  listLoaded: false,
  searchString: '',
  recordsFound: null,
  showAllTags: false,
  currentAuthor: null,
  articlesMarketSupport: false,
};

const articleSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    saveArticle: (
      state,
      {
        payload,
      }: PayloadAction<Pick<BasicArticlesState, 'article' | 'relatedArticles' | 'relatedContent'>>,
    ) => {
      state.article = payload.article;
      state.relatedArticles = payload.relatedArticles;
      state.relatedContent = payload.relatedContent;
    },
    saveArticlesList: (state, { payload }: PayloadAction<IRawArticle[]>) => {
      state.list = payload;
      state.listLoaded = true;
    },
    setRecordsFound: (state, action) => {
      state.recordsFound = action.payload;
    },
    setShowAllTags: (state, action) => {
      state.showAllTags = action.payload;
    },
    setCurrentAuthor: (state, action) => {
      state.currentAuthor = action.payload;
    },
    setRelatedArticles: (state, action) => {
      state.relatedArticles = action.payload;
    },
    setArticlesSupport(state, { payload }: PayloadAction<boolean>) {
      state.articlesMarketSupport = payload;
    },
  },
});

export const {
  saveArticle,
  saveArticlesList,
  setRecordsFound,
  setShowAllTags,
  setCurrentAuthor,
  setRelatedArticles,
  setArticlesSupport,
} = articleSlice.actions;

export default articleSlice.reducer;
