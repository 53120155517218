import { Router } from 'next/router';
import { UseTranslationResponse } from 'react-i18next';
import gazetteer, { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { getAlternativeMarketUrl } from 'lib/landing/get-alternative-market-url';
import { AlternateLink } from 'lib/types';

type I18n = UseTranslationResponse<any, any>['i18n'];

export const LOCAL_STORAGE_KEY = 'locale-suggested';
export const LOCAL_STORAGE_VALUE = 'true';

export interface LocaleSuggestionData {
  href: string;
  text: {
    title: string;
    changeButton: string;
    stayButton: string;
  };
  flagImg?: string;
  targetCountry: string;
  targetLocale: string;
}

export const getDisplayData = ({
  alternateLinks,
  asPath,
  i18n,
  viewerCountry,
}: {
  alternateLinks: AlternateLink[];
  asPath: Router['asPath'];
  i18n: I18n;
  viewerCountry?: CountryCodes | null;
}): LocaleSuggestionData | null => {
  const currentMarket = gazetteer.getMarketByURL(asPath);
  const suggestedMarket = gazetteer.getMarketByCountry(
    viewerCountry ?? currentMarket.country,
    currentMarket.country,
  );

  if (currentMarket.country !== suggestedMarket.country) {
    const alternativeLink = getAlternativeMarketUrl(suggestedMarket, asPath, alternateLinks);
    return getDisplayDataByLocale(suggestedMarket, currentMarket, i18n, alternativeLink);
  }

  return null;
};

const getDisplayDataByLocale = (
  suggestedMarket: Market,
  currentMarket: Market,
  i18n: I18n,
  alternativeLink: string,
) => {
  /**
   * This case is shown when we support translation for given serverCountryCode
   * but user has entered incorrect version, so we suggest using a proper one
   * in their native language.
   *
   * Examples:
   * User with GB country code entering /de site (text in English),
   * or user with DE country code entering /uk site (text in German).
   *
   * It is possible to show the text in a different language here because it should be loaded
   * already in advance from: lib/landing/load-landing-locales.ts
   */

  const text = {
    title: i18n.t('localeChange:localeChangeSuggestion.title', {
      lng: suggestedMarket.language,
      targetCountry: suggestedMarket.getCountryName(),
      countryName: suggestedMarket.getCountryName(),
    }),
    changeButton: i18n.t('localeChange:localeChangeSuggestion.changeCountryButton', {
      lng: suggestedMarket.language,
      targetCountry: suggestedMarket.getCountryName(),
    }),
    stayButton: i18n.t('localeChange:localeChangeSuggestion.keepCountryButton', {
      currentCountry: currentMarket.getCountryName(),
    }),
  };

  const flagImg = suggestedMarket.getCountryFlagImageURL();

  return {
    targetCountry: suggestedMarket.country,
    text,
    targetLocale: suggestedMarket.locale,
    flagImg,
    href: alternativeLink,
  };
};
