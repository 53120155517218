import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import { A, Box, ButtonV2 } from '@bridebook/ui';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { AlternateLink } from 'lib/types';
import type { IViewerCountryCodeResponse } from '../../../pages/api/i18n/get-viewer-country';
import componentStyles from './locale-change-suggestion.style';
import {
  LOCAL_STORAGE_KEY,
  LOCAL_STORAGE_VALUE,
  LocaleSuggestionData,
  getDisplayData,
} from './utils/get-display-data';

interface IProps {
  alternateLinks: AlternateLink[];
  isCordova?: boolean;
}

export const LocaleChangeSuggestion = ({ alternateLinks, isCordova }: IProps) => {
  const market = useMarket();
  const { asPath } = useRouter();
  const { i18n } = useTranslation('localeChange');
  const [displayData, setDisplayData] = useState<LocaleSuggestionData | null>();

  useEffect(() => {
    const shownBefore = Boolean(localStorage.getItem(LOCAL_STORAGE_KEY));
    if (isCordova || !alternateLinks || shownBefore) {
      return setDisplayData(null);
    }

    (async () => {
      try {
        const viewerResponse = await fetch(ApiEndpoint.i18n.getViewerCountry);
        const viewerData: IViewerCountryCodeResponse = await viewerResponse.json();
        const country = viewerData.country;

        if (country == null) {
          throw new Error('Unable to determine viewer country.');
        }

        const viewerMarket = gazetteer.getMarketByCountry(country);

        /**
         * We don't want to suggest markets that are blocked or don't have a supplier directory.
         */
        if (viewerMarket.flags?.blocked === true || viewerMarket.hasAnyDirectory !== true) {
          throw new Error(`The ${country} market is not available.`);
        }

        /**
         * We don't want to suggest markets that are not publicly available (while logged out).
         */
        if (viewerMarket.getLocaleFlags()?.public !== true) {
          throw new Error(`The ${country} market is not publicly available.`);
        }

        const displayDataNew = getDisplayData({
          viewerCountry: country,
          i18n,
          alternateLinks,
          asPath,
        });

        setDisplayData(displayDataNew);
      } catch (error) {
        return setDisplayData(null);
      }
    })();
  }, [isCordova, alternateLinks, i18n, market.country, asPath]);

  if (!displayData) {
    return null;
  }
  const onClose = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, LOCAL_STORAGE_VALUE);
    setDisplayData(null);
  };

  const styles = componentStyles();

  return (
    <Box style={styles.backdrop} data-name="locale-change-suggestion">
      <Box style={styles.modal} gap={3}>
        <Box as="img" w={60} src={displayData.flagImg} />
        <Box mb={4}>{displayData.text.title}</Box>
        <A href={displayData.href} noUnderline w="100%">
          <ButtonV2>{displayData.text.changeButton}</ButtonV2>
        </A>
        <ButtonV2 variant="secondary" onClick={onClose}>
          {displayData.text.stayButton}
        </ButtonV2>
      </Box>
    </Box>
  );
};
