import type {
  AreaFacets,
  QnA,
  SearchFacet,
  SearchWidget,
} from '@bridebook/toolbox/src/seo-facets/get-seo-data';
import { IElasticSupplier, Slug } from '@bridebook/toolbox/src/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import SearchFields from 'app-shared/lib/search/search-fields';
import { ILocationData } from 'app-shared/lib/search/types';
import { formatFiltersFromSearch } from 'lib/search/utils';
import { IFetchSearchPromiseResult } from 'lib/types';

export type BasicSearchState = {
  list: IElasticSupplier[];
  filters: Record<string, any>;
  totalResults: number;
  actualCategory: Slug;
  fields: SearchFields;
  facetsContent: SearchFacet | null;
  faqContent: QnA[];
  relatedAreas: string[];
  areaFacets: AreaFacets | null;
  loaded: boolean;
  locationData: Record<string, ILocationData>;
  searchWidgets: SearchWidget[];
  source: string | null;
};

const initialState: BasicSearchState = {
  list: [],
  totalResults: 0,
  actualCategory: 'venue',
  fields: { ...new SearchFields() },
  facetsContent: null,
  faqContent: [],
  relatedAreas: [],
  areaFacets: {},
  loaded: false,
  locationData: {},
  filters: {},
  searchWidgets: [],
  source: null,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    startSearch: (state) => {
      state.loaded = false;
    },
    saveSearchResult: (state, { payload }: PayloadAction<IFetchSearchPromiseResult>) => {
      state.list = payload.results;
      state.totalResults = payload.totalResults;
      state.actualCategory = payload.fields?.type || '';
      state.fields = { ...payload.fields, page: payload.fields.page || 1 };
      state.facetsContent = payload.facetsContent || null;
      state.faqContent = payload.faqContent || [];
      state.relatedAreas = payload.relatedAreas || [];
      state.areaFacets = payload.areaFacets || null;
      state.loaded = true;
      state.filters = formatFiltersFromSearch(payload.filters);
    },
    saveLocationData: (
      state,
      {
        payload,
      }: PayloadAction<{
        area: string;
        locationData: ILocationData;
      }>,
    ) => {
      state.locationData[payload.area] = payload.locationData;
    },
  },
});

export const { saveSearchResult, saveLocationData, startSearch } = searchSlice.actions;

export default searchSlice.reducer;
