import { BasicStore } from 'app-basic/lib/types';
import createCachedSelector from 're-reselect';

const _tests = (state: BasicStore) => state.abTesting.allTests;

export const getLoaderVisible = (state: BasicStore) => state.abTesting.loaderVisible;

export const getTest = createCachedSelector(
  _tests,
  (_: BasicStore, id: string) => id,
  (abtests, id) => abtests?.find((test) => test.id === id),
)((_, id) => id);
