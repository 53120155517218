import gazetteer, { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import type { Slug } from '@bridebook/toolbox/src/types';
import { IArticle } from 'app-shared/lib/articles/types';
import { AlternateLink } from 'lib/app/types';
import {
  LandingPageIds,
  WebflowLandingPages,
  landingPageExists,
} from 'lib/landing/webflow-page-urls';
import { UrlHelper } from 'lib/url-helper';
import { getLocalisedCanonical } from 'lib/utils/i18n-routing/get-localised-canonical';

/**
 * Returns pathname for the current webflow page, including the market prefix
 */
export const getPathnameForWebflow = (pageId: LandingPageIds, market: Market): string => {
  const { bridebookPath } = WebflowLandingPages[pageId];
  // Returns pathname with the first slash replaced by the /MARKET_PREFIX
  // If pathname is empty then returns an empty string
  return bridebookPath.replace(/^[/]/, `/${market.prefix}`);
};

/**
 * Returns a list of alternate links for corresponding pages specified in the Webflow config.
 * If a UK url exists, then it's also added as an x-default link.
 */
export const getAlternateLinksForWebflow = (pageId: LandingPageIds): AlternateLink[] => {
  const { bridebookPath } = WebflowLandingPages[pageId];

  // Check which indexed markets have a version of this landing page available
  const markets = indexedMarkets.filter((market) => landingPageExists(pageId, market));

  return [
    // Add alternate links for all corresponding URLs for different markets
    ...markets.map((market) => ({
      // Market prefix is required for correct handling of locale home (with just a '/' pathname)
      href: getLocalisedCanonical(market, market.prefix + bridebookPath),
      hrefLang: getFullLanguageTag(market.locale, market.country),
    })),
    // If the countries list contains UK or Global, then add an x-default link to UK url
    ...(landingPageExists(pageId, gazetteer.getMarketByCountry(CountryCodes.GB))
      ? [
          {
            href: getLocalisedCanonical(
              gazetteer.getMarketByCountry(CountryCodes.GB),
              bridebookPath,
            ),
            hrefLang: 'x-default',
          } as AlternateLink,
        ]
      : []),
  ];
};

/**
 * Returns a list of alternate links for a regular page (so other than webflow, global home etc.)
 * Url to the UK site is always generated also as an x-default
 */
export const getAlternateLinksForRegularPage = (
  pathname?: string,
  markets: Market[] = indexedMarkets,
): AlternateLink[] => [
  ...markets.map((market) => ({
    href: getLocalisedCanonical(market, pathname),
    hrefLang: getFullLanguageTag(market.locale, market.country),
  })),
  {
    href: getLocalisedCanonical(gazetteer.getMarketByCountry(CountryCodes.GB), pathname),
    hrefLang: 'x-default',
  },
];

/**
 * Returns hreflang links for search results page, listing all supported locales
 * @param pathname
 * @param market
 */
export const getAlternateLinksForSearch = (pathname: string, market: Market): AlternateLink[] => {
  const originalMarket = gazetteer.getMarketByCountry(market.country);
  return [
    ...market.locales.map((locale) => ({
      href: getLocalisedCanonical(gazetteer.getMarketWithLocale(market.country, locale), pathname),
      hrefLang: getFullLanguageTag(locale, market.country),
    })),
    {
      href: getLocalisedCanonical(originalMarket, pathname),
      hrefLang: 'x-default',
    },
  ];
};

/**
 * Returns hreflang links for a supplier profile, listing all translated locales and market default
 * locale
 * @param pathname
 * @param market
 * @param {string[]} locales - supplier available locales plus default one from original market
 */
export const getAlternateLinksForSupplier = (
  pathname: string,
  market: Market,
  locales: string[],
): AlternateLink[] => {
  const originalMarket = gazetteer.getMarketByCountry(market.country);
  const allLocales = new Set([...locales, originalMarket.locale]);
  return [
    ...Array.from(allLocales).map((locale) => ({
      href: getLocalisedCanonical(gazetteer.getMarketWithLocale(market.country, locale), pathname),
      hrefLang: getFullLanguageTag(locale, market.country),
    })),
    {
      href: getLocalisedCanonical(originalMarket, pathname),
      hrefLang: 'x-default',
    },
  ];
};

/**
 * Returns a list of alternate links for search landing page,
 * based on available supplier types for other languages
 */
export const getAlternateLinksForSearchLanding = ({
  pathname,
  supplierType,
}: {
  pathname: string;
  supplierType?: Slug;
}): AlternateLink[] => {
  if (!supplierType) return [];

  const supportedMarkets = indexedMarkets
    // Get only markets supporting given supplier type
    .filter((market) => market.suppliers.includes(supplierType));

  return getAlternateLinksForRegularPage(pathname, supportedMarkets);
};

/**
 * Returns a list of alternate links for a articles fetched from Elastic
 */
export const getAlternateLinksForArticle = (
  articlesI18n: IArticle['i18n'] = {} as IArticle['i18n'],
): AlternateLink[] => {
  const ukMarket = gazetteer.getMarketByCountry(CountryCodes.GB);
  const ukPrefix = ukMarket.prefix;

  return [
    ...Object.entries(articlesI18n).map(([prefix, data]) => {
      const market = gazetteer.getMarketByURL(prefix);
      return {
        href: getLocalisedCanonical(market, UrlHelper.article.id(data.slug)),
        hrefLang: getFullLanguageTag(market.locale, market.country),
      };
    }),
    // If an article for UK exists, use it as an x-default link
    ...(articlesI18n[ukPrefix]
      ? [
          {
            href: getLocalisedCanonical(
              ukMarket,
              UrlHelper.article.id(articlesI18n[ukPrefix].slug),
            ),
            hrefLang: 'x-default',
          } as AlternateLink,
        ]
      : []),
  ];
};

/* ############################################################################
 *  Helpers
 * ######################################################################### */

/**
 * List of markets for which we can generate alternate links
 */
const indexedMarkets = gazetteer.getMarkets().reduce((acc, market) => {
  // Iterate over market locales and get all that are indexable
  const indexedLocales = market.locales.filter(
    (locale) => market.getLocaleFlags(locale)?.indexable,
  );
  // Return a list of specific markets for each indexable locale
  return [
    ...acc,
    ...indexedLocales.map((locale) => gazetteer.getMarketWithLocale(market.country, locale)),
  ];
}, [] as Market[]);

/**
 * Returns a full 5-letter language tag
 * If the locale is 2 characters long, it adds the country code to create a full tag
 */
export const getFullLanguageTag = (locale: string, country: CountryCodes) =>
  locale.length === 2 ? `${locale}-${country.toUpperCase()}` : locale;
