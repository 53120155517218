import type { SortByOption } from '@bridebook/toolbox/src/search-suppliers/types';
import type { IAddressComponents, Slug } from '@bridebook/toolbox/src/types';

export default class SearchFields {
  constructor() {
    this.type = 'venue';
    this.sort = 'favourites';
    this.area = '';
    this.page = 1;
    this.addressComponents = null;
    this.searchParams = [];
  }

  type: Slug;
  sort: SortByOption;
  area: string;
  page: number;
  addressComponents: IAddressComponents | null;
  searchParams: Array<string>;
}
