import { FelaCSS, alpha, colors } from '@bridebook/ui';

interface IStyles {
  backdrop: FelaCSS;
  modal: FelaCSS;
}

const styles = (): IStyles => ({
  backdrop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    textAlign: 'center',
    zIndex: 1000,
    backgroundColor: alpha(colors.space, 80),
  },

  modal: {
    width: '400px',
    maxWidth: '90vw',
    background: colors.white,
    borderRadius: '8px',
    color: colors.space,
    fontDefaultSemiBold: 16,
    padding: 8,
    pointerEvents: 'all',
    textAlign: 'center',
    alignItems: 'center',

    desktopDesign: {
      borderRadius: '24px',
    },
  },
});

export default styles;
