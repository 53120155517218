import abBasicTestingReducer from 'app-basic/lib/ab-tests/ab-tests-slice';
import app from 'app-basic/lib/app/app-slice';
import articles from 'app-basic/lib/article/article-slice';
import searchBarInfo from 'app-basic/lib/search/search-bar-info-slice';
import search from 'app-basic/lib/search/search-slice';
import { AnalyticsService } from 'app-basic/lib/services/analytics-service';
import supplierDetails from 'app-basic/lib/supplier/supplier-details-slice';
import supplier from 'app-basic/lib/supplier/supplier-slice';
import { BasicStore } from 'app-basic/lib/types';
import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { HYDRATE, createWrapper } from 'app-shared/lib/next-redux-wrapper';

const analytics = null;

// @TODO - store creation requires typechecking
const combinedReducers = combineReducers({
  abTesting: abBasicTestingReducer,
  app,
  articles,
  search,
  supplier,
  supplierDetails,
  searchBarInfo,
});

const hydrate = (state: BasicStore, action: AnyAction): BasicStore => {
  const clientState: BasicStore = state;
  const serverState: BasicStore = action.payload;
  return {
    ...clientState,
    ...serverState,
    /**
     * Preserve the following properties on the client-side between routing
     */
    ...(clientState.abTesting.allTests && {
      abTesting: clientState.abTesting,
    }),
    ...(clientState.searchBarInfo.bounds && {
      searchBarInfo: clientState.searchBarInfo,
    }),
  };
};

const initStore = () => {
  const analyticsService = new AnalyticsService(analytics, {
    sessionId: null,
  });

  return configureStore({
    reducer: (state, action) => {
      if (state && action.type === HYDRATE) {
        return hydrate(state, action);
      }

      return combinedReducers(state, action);
    },
    devTools: {
      name: 'Bridebook Basic',
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { analyticsService },
        },
      }),
  });
};

export type TBasicStore = ReturnType<typeof initStore>;

export const basicWrapper = createWrapper(initStore, { id: 'basic' });
