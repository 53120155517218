import {
  abTestingSlice,
  setAbTestVariant,
  setAbTestsLoaded,
} from '@bridebook/toolbox/src/ab-testing';
import { Experiment, IAbTestingState } from '@bridebook/toolbox/src/ab-testing/lib/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IBasicABTestingState extends IAbTestingState {
  allTests?: Experiment[];
  loaderVisible: boolean;
}

const initialState: IBasicABTestingState = {
  abTests: {},
  abTestsLoaded: false,
  loaderVisible: false,
};

const abBasicTestingReducer = createSlice({
  name: 'abTesting',
  initialState,
  reducers: {
    setAllTests(state, action: PayloadAction<Experiment[] | undefined>) {
      state.allTests = action.payload;
    },
    showFullpageLoader(state, action: PayloadAction<boolean>) {
      state.loaderVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAbTestVariant, abTestingSlice.caseReducers.setAbTestVariant)
      .addCase(setAbTestsLoaded, abTestingSlice.caseReducers.setAbTestsLoaded);
  },
});

export const { setAllTests, showFullpageLoader } = abBasicTestingReducer.actions;

export default abBasicTestingReducer.reducer;
