import { useDispatchWindowResize } from 'app-basic/components/hooks/use-dispatch-window-resize';
import { useGlobalABTests } from 'app-basic/lib/ab-tests/hooks/use-global-ab-tests';
import { getAlternateLinks } from 'app-basic/lib/app/selectors';
import { useSelector } from 'app-basic/lib/utils';
import { IRenderer } from 'fela';
import { Router as NextRouter } from 'next/dist/client/router';
import { useRouter } from 'next/router';
import React, { FC, PropsWithChildren } from 'react';
import { FelaProvider } from '@bridebook/ui';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocaleChangeSuggestion } from 'app-shared/components/locale-change-suggestion/locale-change-suggestion';
import { AppBannerScript, MetaRouterScript } from 'app-shared/lib/app/scripts';
import { env } from 'lib/env';
import addUKPluralUpdate from 'lib/i18n/addUKPluralUpdate';
import { UrlHelper } from 'lib/url-helper';

export interface LayoutBasicProps {
  renderer?: IRenderer;
  router: NextRouter;
}

export const LayoutBasic: FC<PropsWithChildren<LayoutBasicProps>> = ({
  children,
  renderer,
  router,
}) => {
  addUKPluralUpdate();
  useDispatchWindowResize();
  useGlobalABTests();
  const { query, pathname } = useRouter();
  /**
   * Query supplier param is added in cms, for previewing supplier profile. For this case we don't
   * want to show locale change suggestion.
   * We also don't want to show locale change suggestion when users are visiting a couple's wedding website.
   */
  const hideLocaleChangeSuggestion = query.supplier || pathname.includes(`${UrlHelper.for.main}/`);
  const showReactQueryDevtools = Object.keys(router.query).includes('reactQuery');

  const alternateLinks = useSelector(getAlternateLinks);
  return (
    <>
      <AppBannerScript showBanner />
      <MetaRouterScript isCordova={false} />
      <FelaProvider renderer={renderer}>
        {!hideLocaleChangeSuggestion && <LocaleChangeSuggestion alternateLinks={alternateLinks} />}
        {children}
        {!env.IS_PRODUCTION_BUILD && showReactQueryDevtools && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </FelaProvider>
    </>
  );
};
